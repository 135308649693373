const polish = {
  default: [
    "\u02DB 1 2 3 4 5 6 7 8 9 0 + ' {bksp}",
    "{tab} q w e r t z u i o p \u017C \u015B",
    "{lock} a s d f g h j k l \u0142 \u0105 \u00F3 {enter}",
    "{shift} < y x c v b n m , . - {shift}",
    ".com @ {space}"
  ],
  shift: [
    '\u00B7 ! " # \u00A4 % & / ( ) = ? * {bksp}',
    "{tab} Q W E R T Z U I O P \u0144 \u0107",
    "{lock} A S D F G H J K L \u0141 \u0119 \u017A {enter}",
    "{shift} > Y X C V B N M ; : _ {shift}",
    ".com @ {space}"
  ]
};

export default polish;
