const czech = {
  default: [
    "; + \u011B \u0161 \u010D \u0159 \u017E \u00FD \u00E1 \u00ED \u00E9 \u00B4 {bksp}",
    "{tab} q w e r t y u i o p \u00FA ) \u00A8",
    "{lock} a s d f g h j k l \u016F \u00A7 {enter}",
    "{shift} \\ z x c v b n m , . - {shift}",
    ".com @ {space}"
  ],
  shift: [
    "\u00b0 1 2 3 4 5 6 7 8 9 0 % \u02c7 {bksp}",
    "{tab} Q W E R T Y U I O P / ( '",
    '{lock} A S D F G H J K L " ! {enter}',
    "{shift} | Z X C V B N M ? : _ {shift}",
    ".com @ {space}"
  ]
};
export default czech;
