const assamese = {
  default: [
    "\u0965 \u09e7 \u09e8 \u09e9 \u09ea \u09eb \u09ec \u09ed \u09ee \u09ef \u09e6 \u002d \u09c3 {bksp}",
    "{tab} \u09cc \u09c8 \u09be \u09c0 \u09c2 \u09ac \u09b9 \u0997 \u09a6 \u099c \u09a1 \u09bc",
    "\u09cb \u09c7 \u09cd \u09bf \u09c1 \u09aa \u09f0 \u0995 \u09a4 \u099a \u099f {enter}",
    "{shift} \u0982 \u09ae \u09a8 \u09f1 \u09b2 \u09b8 \u002c \u002e \u09df {shift}",
    ".com @ {space}"
  ],
  shift: [
    "\u0021 \u0040 ( ) \u0983 \u098b {bksp}",
    "{tab} \u0994 \u0990 \u0986 \u0998 \u098a \u09ad \u0999 \u0998 \u09a7 \u099d \u09a2 \u099e",
    "\u0993 \u098f \u0985 \u0987 \u0989 \u09ab  \u0996 \u09a5 \u099b \u099b \u09a0 {enter}",
    "{shift} \u0981 \u09a3 \u09b6 \u09b7 \u0964 \u09af {shift}",
    ".com @ {space}"
  ]
};

export default assamese;
