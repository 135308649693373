const greek = {
  default: [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} ; ς ε ρ τ υ θ ι ο π [ ] \\",
    "{lock} α σ δ φ γ η ξ κ λ ΄ ' {enter}",
    "{shift} < ζ χ ψ ω β ν μ , . / {shift}",
    ".com @ {space}"
  ],
  shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} : ΅ Ε Ρ Τ Υ Θ Ι Ο Π { } |",
    '{lock} Α Σ Δ Φ Γ Η Ξ Κ Λ ¨ " {enter}',
    "{shift} > Ζ Χ Ψ Ω Β Ν Μ < > ? {shift}",
    ".com @ {space}"
  ]
};

export default greek;
